{
  "0": {
    "name": "identity",
    "tag": "multihash",
    "status": "permanent",
    "description": "raw binary",
    "code": 0
  },
  "1": {
    "name": "cidv1",
    "tag": "cid",
    "status": "permanent",
    "description": "CIDv1",
    "code": 1
  },
  "2": {
    "name": "cidv2",
    "tag": "cid",
    "status": "draft",
    "description": "CIDv2",
    "code": 2
  },
  "3": {
    "name": "cidv3",
    "tag": "cid",
    "status": "draft",
    "description": "CIDv3",
    "code": 3
  },
  "4": {
    "name": "ip4",
    "tag": "multiaddr",
    "status": "permanent",
    "description": "",
    "code": 4
  },
  "6": {
    "name": "tcp",
    "tag": "multiaddr",
    "status": "permanent",
    "description": "",
    "code": 6
  },
  "17": {
    "name": "sha1",
    "tag": "multihash",
    "status": "permanent",
    "description": "",
    "code": 17
  },
  "18": {
    "name": "sha2-256",
    "tag": "multihash",
    "status": "permanent",
    "description": "",
    "code": 18
  },
  "19": {
    "name": "sha2-512",
    "tag": "multihash",
    "status": "permanent",
    "description": "",
    "code": 19
  },
  "20": {
    "name": "sha3-512",
    "tag": "multihash",
    "status": "permanent",
    "description": "",
    "code": 20
  },
  "21": {
    "name": "sha3-384",
    "tag": "multihash",
    "status": "permanent",
    "description": "",
    "code": 21
  },
  "22": {
    "name": "sha3-256",
    "tag": "multihash",
    "status": "permanent",
    "description": "",
    "code": 22
  },
  "23": {
    "name": "sha3-224",
    "tag": "multihash",
    "status": "permanent",
    "description": "",
    "code": 23
  },
  "24": {
    "name": "shake-128",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 24
  },
  "25": {
    "name": "shake-256",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 25
  },
  "26": {
    "name": "keccak-224",
    "tag": "multihash",
    "status": "draft",
    "description": "keccak has variable output length. The number specifies the core length",
    "code": 26
  },
  "27": {
    "name": "keccak-256",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 27
  },
  "28": {
    "name": "keccak-384",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 28
  },
  "29": {
    "name": "keccak-512",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 29
  },
  "30": {
    "name": "blake3",
    "tag": "multihash",
    "status": "draft",
    "description": "BLAKE3 has a default 32 byte output length. The maximum length is (2^64)-1 bytes.",
    "code": 30
  },
  "32": {
    "name": "sha2-384",
    "tag": "multihash",
    "status": "permanent",
    "description": "aka SHA-384; as specified by FIPS 180-4.",
    "code": 32
  },
  "33": {
    "name": "dccp",
    "tag": "multiaddr",
    "status": "draft",
    "description": "",
    "code": 33
  },
  "34": {
    "name": "murmur3-x64-64",
    "tag": "hash",
    "status": "permanent",
    "description": "The first 64-bits of a murmur3-x64-128 - used for UnixFS directory sharding.",
    "code": 34
  },
  "35": {
    "name": "murmur3-32",
    "tag": "hash",
    "status": "draft",
    "description": "",
    "code": 35
  },
  "41": {
    "name": "ip6",
    "tag": "multiaddr",
    "status": "permanent",
    "description": "",
    "code": 41
  },
  "42": {
    "name": "ip6zone",
    "tag": "multiaddr",
    "status": "draft",
    "description": "",
    "code": 42
  },
  "43": {
    "name": "ipcidr",
    "tag": "multiaddr",
    "status": "draft",
    "description": "CIDR mask for IP addresses",
    "code": 43
  },
  "47": {
    "name": "path",
    "tag": "namespace",
    "status": "permanent",
    "description": "Namespace for string paths. Corresponds to `/` in ASCII.",
    "code": 47
  },
  "48": {
    "name": "multicodec",
    "tag": "multiformat",
    "status": "draft",
    "description": "",
    "code": 48
  },
  "49": {
    "name": "multihash",
    "tag": "multiformat",
    "status": "draft",
    "description": "",
    "code": 49
  },
  "50": {
    "name": "multiaddr",
    "tag": "multiformat",
    "status": "draft",
    "description": "",
    "code": 50
  },
  "51": {
    "name": "multibase",
    "tag": "multiformat",
    "status": "draft",
    "description": "",
    "code": 51
  },
  "52": {
    "name": "varsig",
    "tag": "multiformat",
    "status": "draft",
    "description": "Variable signature (varsig) multiformat",
    "code": 52
  },
  "53": {
    "name": "dns",
    "tag": "multiaddr",
    "status": "permanent",
    "description": "",
    "code": 53
  },
  "54": {
    "name": "dns4",
    "tag": "multiaddr",
    "status": "permanent",
    "description": "",
    "code": 54
  },
  "55": {
    "name": "dns6",
    "tag": "multiaddr",
    "status": "permanent",
    "description": "",
    "code": 55
  },
  "56": {
    "name": "dnsaddr",
    "tag": "multiaddr",
    "status": "permanent",
    "description": "",
    "code": 56
  },
  "80": {
    "name": "protobuf",
    "tag": "serialization",
    "status": "draft",
    "description": "Protocol Buffers",
    "code": 80
  },
  "81": {
    "name": "cbor",
    "tag": "ipld",
    "status": "permanent",
    "description": "CBOR",
    "code": 81
  },
  "85": {
    "name": "raw",
    "tag": "ipld",
    "status": "permanent",
    "description": "raw binary",
    "code": 85
  },
  "86": {
    "name": "dbl-sha2-256",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 86
  },
  "96": {
    "name": "rlp",
    "tag": "serialization",
    "status": "draft",
    "description": "recursive length prefix",
    "code": 96
  },
  "99": {
    "name": "bencode",
    "tag": "serialization",
    "status": "draft",
    "description": "bencode",
    "code": 99
  },
  "112": {
    "name": "dag-pb",
    "tag": "ipld",
    "status": "permanent",
    "description": "MerkleDAG protobuf",
    "code": 112
  },
  "113": {
    "name": "dag-cbor",
    "tag": "ipld",
    "status": "permanent",
    "description": "MerkleDAG cbor",
    "code": 113
  },
  "114": {
    "name": "libp2p-key",
    "tag": "ipld",
    "status": "permanent",
    "description": "Libp2p Public Key",
    "code": 114
  },
  "120": {
    "name": "git-raw",
    "tag": "ipld",
    "status": "permanent",
    "description": "Raw Git object",
    "code": 120
  },
  "123": {
    "name": "torrent-info",
    "tag": "ipld",
    "status": "draft",
    "description": "Torrent file info field (bencoded)",
    "code": 123
  },
  "124": {
    "name": "torrent-file",
    "tag": "ipld",
    "status": "draft",
    "description": "Torrent file (bencoded)",
    "code": 124
  },
  "129": {
    "name": "leofcoin-block",
    "tag": "ipld",
    "status": "draft",
    "description": "Leofcoin Block",
    "code": 129
  },
  "130": {
    "name": "leofcoin-tx",
    "tag": "ipld",
    "status": "draft",
    "description": "Leofcoin Transaction",
    "code": 130
  },
  "131": {
    "name": "leofcoin-pr",
    "tag": "ipld",
    "status": "draft",
    "description": "Leofcoin Peer Reputation",
    "code": 131
  },
  "132": {
    "name": "sctp",
    "tag": "multiaddr",
    "status": "draft",
    "description": "",
    "code": 132
  },
  "133": {
    "name": "dag-jose",
    "tag": "ipld",
    "status": "draft",
    "description": "MerkleDAG JOSE",
    "code": 133
  },
  "134": {
    "name": "dag-cose",
    "tag": "ipld",
    "status": "draft",
    "description": "MerkleDAG COSE",
    "code": 134
  },
  "140": {
    "name": "lbry",
    "tag": "namespace",
    "status": "draft",
    "description": "LBRY Address",
    "code": 140
  },
  "144": {
    "name": "eth-block",
    "tag": "ipld",
    "status": "permanent",
    "description": "Ethereum Header (RLP)",
    "code": 144
  },
  "145": {
    "name": "eth-block-list",
    "tag": "ipld",
    "status": "permanent",
    "description": "Ethereum Header List (RLP)",
    "code": 145
  },
  "146": {
    "name": "eth-tx-trie",
    "tag": "ipld",
    "status": "permanent",
    "description": "Ethereum Transaction Trie (Eth-Trie)",
    "code": 146
  },
  "147": {
    "name": "eth-tx",
    "tag": "ipld",
    "status": "permanent",
    "description": "Ethereum Transaction (MarshalBinary)",
    "code": 147
  },
  "148": {
    "name": "eth-tx-receipt-trie",
    "tag": "ipld",
    "status": "permanent",
    "description": "Ethereum Transaction Receipt Trie (Eth-Trie)",
    "code": 148
  },
  "149": {
    "name": "eth-tx-receipt",
    "tag": "ipld",
    "status": "permanent",
    "description": "Ethereum Transaction Receipt (MarshalBinary)",
    "code": 149
  },
  "150": {
    "name": "eth-state-trie",
    "tag": "ipld",
    "status": "permanent",
    "description": "Ethereum State Trie (Eth-Secure-Trie)",
    "code": 150
  },
  "151": {
    "name": "eth-account-snapshot",
    "tag": "ipld",
    "status": "permanent",
    "description": "Ethereum Account Snapshot (RLP)",
    "code": 151
  },
  "152": {
    "name": "eth-storage-trie",
    "tag": "ipld",
    "status": "permanent",
    "description": "Ethereum Contract Storage Trie (Eth-Secure-Trie)",
    "code": 152
  },
  "153": {
    "name": "eth-receipt-log-trie",
    "tag": "ipld",
    "status": "draft",
    "description": "Ethereum Transaction Receipt Log Trie (Eth-Trie)",
    "code": 153
  },
  "154": {
    "name": "eth-receipt-log",
    "tag": "ipld",
    "status": "draft",
    "description": "Ethereum Transaction Receipt Log (RLP)",
    "code": 154
  },
  "160": {
    "name": "aes-128",
    "tag": "key",
    "status": "draft",
    "description": "128-bit AES symmetric key",
    "code": 160
  },
  "161": {
    "name": "aes-192",
    "tag": "key",
    "status": "draft",
    "description": "192-bit AES symmetric key",
    "code": 161
  },
  "162": {
    "name": "aes-256",
    "tag": "key",
    "status": "draft",
    "description": "256-bit AES symmetric key",
    "code": 162
  },
  "163": {
    "name": "chacha-128",
    "tag": "key",
    "status": "draft",
    "description": "128-bit ChaCha symmetric key",
    "code": 163
  },
  "164": {
    "name": "chacha-256",
    "tag": "key",
    "status": "draft",
    "description": "256-bit ChaCha symmetric key",
    "code": 164
  },
  "176": {
    "name": "bitcoin-block",
    "tag": "ipld",
    "status": "permanent",
    "description": "Bitcoin Block",
    "code": 176
  },
  "177": {
    "name": "bitcoin-tx",
    "tag": "ipld",
    "status": "permanent",
    "description": "Bitcoin Tx",
    "code": 177
  },
  "178": {
    "name": "bitcoin-witness-commitment",
    "tag": "ipld",
    "status": "permanent",
    "description": "Bitcoin Witness Commitment",
    "code": 178
  },
  "192": {
    "name": "zcash-block",
    "tag": "ipld",
    "status": "permanent",
    "description": "Zcash Block",
    "code": 192
  },
  "193": {
    "name": "zcash-tx",
    "tag": "ipld",
    "status": "permanent",
    "description": "Zcash Tx",
    "code": 193
  },
  "202": {
    "name": "caip-50",
    "tag": "multiformat",
    "status": "draft",
    "description": "CAIP-50 multi-chain account id",
    "code": 202
  },
  "206": {
    "name": "streamid",
    "tag": "namespace",
    "status": "draft",
    "description": "Ceramic Stream Id",
    "code": 206
  },
  "208": {
    "name": "stellar-block",
    "tag": "ipld",
    "status": "draft",
    "description": "Stellar Block",
    "code": 208
  },
  "209": {
    "name": "stellar-tx",
    "tag": "ipld",
    "status": "draft",
    "description": "Stellar Tx",
    "code": 209
  },
  "212": {
    "name": "md4",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 212
  },
  "213": {
    "name": "md5",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 213
  },
  "224": {
    "name": "decred-block",
    "tag": "ipld",
    "status": "draft",
    "description": "Decred Block",
    "code": 224
  },
  "225": {
    "name": "decred-tx",
    "tag": "ipld",
    "status": "draft",
    "description": "Decred Tx",
    "code": 225
  },
  "226": {
    "name": "ipld",
    "tag": "namespace",
    "status": "draft",
    "description": "IPLD path",
    "code": 226
  },
  "227": {
    "name": "ipfs",
    "tag": "namespace",
    "status": "draft",
    "description": "IPFS path",
    "code": 227
  },
  "228": {
    "name": "swarm",
    "tag": "namespace",
    "status": "draft",
    "description": "Swarm path",
    "code": 228
  },
  "229": {
    "name": "ipns",
    "tag": "namespace",
    "status": "draft",
    "description": "IPNS path",
    "code": 229
  },
  "230": {
    "name": "zeronet",
    "tag": "namespace",
    "status": "draft",
    "description": "ZeroNet site address",
    "code": 230
  },
  "231": {
    "name": "secp256k1-pub",
    "tag": "key",
    "status": "draft",
    "description": "Secp256k1 public key (compressed)",
    "code": 231
  },
  "232": {
    "name": "dnslink",
    "tag": "namespace",
    "status": "permanent",
    "description": "DNSLink path",
    "code": 232
  },
  "234": {
    "name": "bls12_381-g1-pub",
    "tag": "key",
    "status": "draft",
    "description": "BLS12-381 public key in the G1 field",
    "code": 234
  },
  "235": {
    "name": "bls12_381-g2-pub",
    "tag": "key",
    "status": "draft",
    "description": "BLS12-381 public key in the G2 field",
    "code": 235
  },
  "236": {
    "name": "x25519-pub",
    "tag": "key",
    "status": "draft",
    "description": "Curve25519 public key",
    "code": 236
  },
  "237": {
    "name": "ed25519-pub",
    "tag": "key",
    "status": "draft",
    "description": "Ed25519 public key",
    "code": 237
  },
  "238": {
    "name": "bls12_381-g1g2-pub",
    "tag": "key",
    "status": "draft",
    "description": "BLS12-381 concatenated public keys in both the G1 and G2 fields",
    "code": 238
  },
  "239": {
    "name": "sr25519-pub",
    "tag": "key",
    "status": "draft",
    "description": "Sr25519 public key",
    "code": 239
  },
  "240": {
    "name": "dash-block",
    "tag": "ipld",
    "status": "draft",
    "description": "Dash Block",
    "code": 240
  },
  "241": {
    "name": "dash-tx",
    "tag": "ipld",
    "status": "draft",
    "description": "Dash Tx",
    "code": 241
  },
  "250": {
    "name": "swarm-manifest",
    "tag": "ipld",
    "status": "draft",
    "description": "Swarm Manifest",
    "code": 250
  },
  "251": {
    "name": "swarm-feed",
    "tag": "ipld",
    "status": "draft",
    "description": "Swarm Feed",
    "code": 251
  },
  "252": {
    "name": "beeson",
    "tag": "ipld",
    "status": "draft",
    "description": "Swarm BeeSon",
    "code": 252
  },
  "273": {
    "name": "udp",
    "tag": "multiaddr",
    "status": "draft",
    "description": "",
    "code": 273
  },
  "275": {
    "name": "p2p-webrtc-star",
    "tag": "multiaddr",
    "status": "deprecated",
    "description": "Use webrtc or webrtc-direct instead",
    "code": 275
  },
  "276": {
    "name": "p2p-webrtc-direct",
    "tag": "multiaddr",
    "status": "deprecated",
    "description": "Use webrtc or webrtc-direct instead",
    "code": 276
  },
  "277": {
    "name": "p2p-stardust",
    "tag": "multiaddr",
    "status": "deprecated",
    "description": "",
    "code": 277
  },
  "280": {
    "name": "webrtc-direct",
    "tag": "multiaddr",
    "status": "draft",
    "description": "ICE-lite webrtc transport with SDP munging during connection establishment and without use of a STUN server",
    "code": 280
  },
  "281": {
    "name": "webrtc",
    "tag": "multiaddr",
    "status": "draft",
    "description": "webrtc transport where connection establishment is according to w3c spec",
    "code": 281
  },
  "290": {
    "name": "p2p-circuit",
    "tag": "multiaddr",
    "status": "permanent",
    "description": "",
    "code": 290
  },
  "297": {
    "name": "dag-json",
    "tag": "ipld",
    "status": "permanent",
    "description": "MerkleDAG json",
    "code": 297
  },
  "301": {
    "name": "udt",
    "tag": "multiaddr",
    "status": "draft",
    "description": "",
    "code": 301
  },
  "302": {
    "name": "utp",
    "tag": "multiaddr",
    "status": "draft",
    "description": "",
    "code": 302
  },
  "306": {
    "name": "crc32",
    "tag": "hash",
    "status": "draft",
    "description": "CRC-32 non-cryptographic hash algorithm (IEEE 802.3)",
    "code": 306
  },
  "356": {
    "name": "crc64-ecma",
    "tag": "hash",
    "status": "draft",
    "description": "CRC-64 non-cryptographic hash algorithm (ECMA-182 - Annex B)",
    "code": 356
  },
  "400": {
    "name": "unix",
    "tag": "multiaddr",
    "status": "permanent",
    "description": "",
    "code": 400
  },
  "406": {
    "name": "thread",
    "tag": "multiaddr",
    "status": "draft",
    "description": "Textile Thread",
    "code": 406
  },
  "421": {
    "name": "p2p",
    "tag": "multiaddr",
    "status": "permanent",
    "description": "libp2p",
    "code": 421
  },
  "443": {
    "name": "https",
    "tag": "multiaddr",
    "status": "draft",
    "description": "",
    "code": 443
  },
  "444": {
    "name": "onion",
    "tag": "multiaddr",
    "status": "draft",
    "description": "",
    "code": 444
  },
  "445": {
    "name": "onion3",
    "tag": "multiaddr",
    "status": "draft",
    "description": "",
    "code": 445
  },
  "446": {
    "name": "garlic64",
    "tag": "multiaddr",
    "status": "draft",
    "description": "I2P base64 (raw public key)",
    "code": 446
  },
  "447": {
    "name": "garlic32",
    "tag": "multiaddr",
    "status": "draft",
    "description": "I2P base32 (hashed public key or encoded public key/checksum+optional secret)",
    "code": 447
  },
  "448": {
    "name": "tls",
    "tag": "multiaddr",
    "status": "draft",
    "description": "",
    "code": 448
  },
  "449": {
    "name": "sni",
    "tag": "multiaddr",
    "status": "draft",
    "description": "Server Name Indication RFC 6066 § 3",
    "code": 449
  },
  "454": {
    "name": "noise",
    "tag": "multiaddr",
    "status": "draft",
    "description": "",
    "code": 454
  },
  "456": {
    "name": "shs",
    "tag": "multiaddr",
    "status": "draft",
    "description": "Secure Scuttlebutt - Secret Handshake Stream",
    "code": 456
  },
  "460": {
    "name": "quic",
    "tag": "multiaddr",
    "status": "permanent",
    "description": "",
    "code": 460
  },
  "461": {
    "name": "quic-v1",
    "tag": "multiaddr",
    "status": "permanent",
    "description": "",
    "code": 461
  },
  "465": {
    "name": "webtransport",
    "tag": "multiaddr",
    "status": "draft",
    "description": "",
    "code": 465
  },
  "466": {
    "name": "certhash",
    "tag": "multiaddr",
    "status": "draft",
    "description": "TLS certificate's fingerprint as a multihash",
    "code": 466
  },
  "477": {
    "name": "ws",
    "tag": "multiaddr",
    "status": "permanent",
    "description": "",
    "code": 477
  },
  "478": {
    "name": "wss",
    "tag": "multiaddr",
    "status": "permanent",
    "description": "",
    "code": 478
  },
  "479": {
    "name": "p2p-websocket-star",
    "tag": "multiaddr",
    "status": "permanent",
    "description": "",
    "code": 479
  },
  "480": {
    "name": "http",
    "tag": "multiaddr",
    "status": "draft",
    "description": "",
    "code": 480
  },
  "496": {
    "name": "swhid-1-snp",
    "tag": "ipld",
    "status": "draft",
    "description": "SoftWare Heritage persistent IDentifier version 1 snapshot",
    "code": 496
  },
  "512": {
    "name": "json",
    "tag": "ipld",
    "status": "permanent",
    "description": "JSON (UTF-8-encoded)",
    "code": 512
  },
  "513": {
    "name": "messagepack",
    "tag": "serialization",
    "status": "draft",
    "description": "MessagePack",
    "code": 513
  },
  "514": {
    "name": "car",
    "tag": "serialization",
    "status": "draft",
    "description": "Content Addressable aRchive (CAR)",
    "code": 514
  },
  "768": {
    "name": "ipns-record",
    "tag": "serialization",
    "status": "permanent",
    "description": "Signed IPNS Record",
    "code": 768
  },
  "769": {
    "name": "libp2p-peer-record",
    "tag": "libp2p",
    "status": "permanent",
    "description": "libp2p peer record type",
    "code": 769
  },
  "770": {
    "name": "libp2p-relay-rsvp",
    "tag": "libp2p",
    "status": "permanent",
    "description": "libp2p relay reservation voucher",
    "code": 770
  },
  "777": {
    "name": "memorytransport",
    "tag": "libp2p",
    "status": "permanent",
    "description": "in memory transport for self-dialing and testing; arbitrary",
    "code": 777
  },
  "1024": {
    "name": "car-index-sorted",
    "tag": "serialization",
    "status": "draft",
    "description": "CARv2 IndexSorted index format",
    "code": 1024
  },
  "1025": {
    "name": "car-multihash-index-sorted",
    "tag": "serialization",
    "status": "draft",
    "description": "CARv2 MultihashIndexSorted index format",
    "code": 1025
  },
  "2304": {
    "name": "transport-bitswap",
    "tag": "transport",
    "status": "draft",
    "description": "Bitswap datatransfer",
    "code": 2304
  },
  "2320": {
    "name": "transport-graphsync-filecoinv1",
    "tag": "transport",
    "status": "draft",
    "description": "Filecoin graphsync datatransfer",
    "code": 2320
  },
  "2336": {
    "name": "transport-ipfs-gateway-http",
    "tag": "transport",
    "status": "draft",
    "description": "HTTP IPFS Gateway trustless datatransfer",
    "code": 2336
  },
  "3357": {
    "name": "multidid",
    "tag": "multiformat",
    "status": "draft",
    "description": "Compact encoding for Decentralized Identifers",
    "code": 3357
  },
  "4113": {
    "name": "fr32-sha2-256-trunc254-padded-binary-tree",
    "tag": "multihash",
    "status": "draft",
    "description": "A balanced binary tree hash used in Filecoin Piece Commitments.",
    "code": 4113
  },
  "4114": {
    "name": "sha2-256-trunc254-padded",
    "tag": "multihash",
    "status": "permanent",
    "description": "SHA2-256 with the two most significant bits from the last byte zeroed (as via a mask with 0b00111111) - used for proving trees as in Filecoin",
    "code": 4114
  },
  "4115": {
    "name": "sha2-224",
    "tag": "multihash",
    "status": "permanent",
    "description": "aka SHA-224; as specified by FIPS 180-4.",
    "code": 4115
  },
  "4116": {
    "name": "sha2-512-224",
    "tag": "multihash",
    "status": "permanent",
    "description": "aka SHA-512/224; as specified by FIPS 180-4.",
    "code": 4116
  },
  "4117": {
    "name": "sha2-512-256",
    "tag": "multihash",
    "status": "permanent",
    "description": "aka SHA-512/256; as specified by FIPS 180-4.",
    "code": 4117
  },
  "4130": {
    "name": "murmur3-x64-128",
    "tag": "hash",
    "status": "draft",
    "description": "",
    "code": 4130
  },
  "4178": {
    "name": "ripemd-128",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 4178
  },
  "4179": {
    "name": "ripemd-160",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 4179
  },
  "4180": {
    "name": "ripemd-256",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 4180
  },
  "4181": {
    "name": "ripemd-320",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 4181
  },
  "4352": {
    "name": "x11",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 4352
  },
  "4608": {
    "name": "p256-pub",
    "tag": "key",
    "status": "draft",
    "description": "P-256 public Key (compressed)",
    "code": 4608
  },
  "4609": {
    "name": "p384-pub",
    "tag": "key",
    "status": "draft",
    "description": "P-384 public Key (compressed)",
    "code": 4609
  },
  "4610": {
    "name": "p521-pub",
    "tag": "key",
    "status": "draft",
    "description": "P-521 public Key (compressed)",
    "code": 4610
  },
  "4611": {
    "name": "ed448-pub",
    "tag": "key",
    "status": "draft",
    "description": "Ed448 public Key",
    "code": 4611
  },
  "4612": {
    "name": "x448-pub",
    "tag": "key",
    "status": "draft",
    "description": "X448 public Key",
    "code": 4612
  },
  "4613": {
    "name": "rsa-pub",
    "tag": "key",
    "status": "draft",
    "description": "RSA public key. DER-encoded ASN.1 type RSAPublicKey according to IETF RFC 8017 (PKCS #1)",
    "code": 4613
  },
  "4614": {
    "name": "sm2-pub",
    "tag": "key",
    "status": "draft",
    "description": "SM2 public key (compressed)",
    "code": 4614
  },
  "4864": {
    "name": "ed25519-priv",
    "tag": "key",
    "status": "draft",
    "description": "Ed25519 private key",
    "code": 4864
  },
  "4865": {
    "name": "secp256k1-priv",
    "tag": "key",
    "status": "draft",
    "description": "Secp256k1 private key",
    "code": 4865
  },
  "4866": {
    "name": "x25519-priv",
    "tag": "key",
    "status": "draft",
    "description": "Curve25519 private key",
    "code": 4866
  },
  "4867": {
    "name": "sr25519-priv",
    "tag": "key",
    "status": "draft",
    "description": "Sr25519 private key",
    "code": 4867
  },
  "4869": {
    "name": "rsa-priv",
    "tag": "key",
    "status": "draft",
    "description": "RSA private key",
    "code": 4869
  },
  "4870": {
    "name": "p256-priv",
    "tag": "key",
    "status": "draft",
    "description": "P-256 private key",
    "code": 4870
  },
  "4871": {
    "name": "p384-priv",
    "tag": "key",
    "status": "draft",
    "description": "P-384 private key",
    "code": 4871
  },
  "4872": {
    "name": "p521-priv",
    "tag": "key",
    "status": "draft",
    "description": "P-521 private key",
    "code": 4872
  },
  "7425": {
    "name": "kangarootwelve",
    "tag": "multihash",
    "status": "draft",
    "description": "KangarooTwelve is an extendable-output hash function based on Keccak-p",
    "code": 7425
  },
  "8192": {
    "name": "aes-gcm-256",
    "tag": "encryption",
    "status": "draft",
    "description": "AES Galois/Counter Mode with 256-bit key and 12-byte IV",
    "code": 8192
  },
  "16194": {
    "name": "silverpine",
    "tag": "multiaddr",
    "status": "draft",
    "description": "Experimental QUIC over yggdrasil and ironwood routing protocol",
    "code": 16194
  },
  "21325": {
    "name": "sm3-256",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 21325
  },
  "28690": {
    "name": "sha256a",
    "tag": "hash",
    "status": "draft",
    "description": "The sum of multiple sha2-256 hashes; as specified by Ceramic CIP-124.",
    "code": 28690
  },
  "45569": {
    "name": "blake2b-8",
    "tag": "multihash",
    "status": "draft",
    "description": "Blake2b consists of 64 output lengths that give different hashes",
    "code": 45569
  },
  "45570": {
    "name": "blake2b-16",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45570
  },
  "45571": {
    "name": "blake2b-24",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45571
  },
  "45572": {
    "name": "blake2b-32",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45572
  },
  "45573": {
    "name": "blake2b-40",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45573
  },
  "45574": {
    "name": "blake2b-48",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45574
  },
  "45575": {
    "name": "blake2b-56",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45575
  },
  "45576": {
    "name": "blake2b-64",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45576
  },
  "45577": {
    "name": "blake2b-72",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45577
  },
  "45578": {
    "name": "blake2b-80",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45578
  },
  "45579": {
    "name": "blake2b-88",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45579
  },
  "45580": {
    "name": "blake2b-96",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45580
  },
  "45581": {
    "name": "blake2b-104",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45581
  },
  "45582": {
    "name": "blake2b-112",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45582
  },
  "45583": {
    "name": "blake2b-120",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45583
  },
  "45584": {
    "name": "blake2b-128",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45584
  },
  "45585": {
    "name": "blake2b-136",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45585
  },
  "45586": {
    "name": "blake2b-144",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45586
  },
  "45587": {
    "name": "blake2b-152",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45587
  },
  "45588": {
    "name": "blake2b-160",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45588
  },
  "45589": {
    "name": "blake2b-168",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45589
  },
  "45590": {
    "name": "blake2b-176",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45590
  },
  "45591": {
    "name": "blake2b-184",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45591
  },
  "45592": {
    "name": "blake2b-192",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45592
  },
  "45593": {
    "name": "blake2b-200",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45593
  },
  "45594": {
    "name": "blake2b-208",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45594
  },
  "45595": {
    "name": "blake2b-216",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45595
  },
  "45596": {
    "name": "blake2b-224",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45596
  },
  "45597": {
    "name": "blake2b-232",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45597
  },
  "45598": {
    "name": "blake2b-240",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45598
  },
  "45599": {
    "name": "blake2b-248",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45599
  },
  "45600": {
    "name": "blake2b-256",
    "tag": "multihash",
    "status": "permanent",
    "description": "",
    "code": 45600
  },
  "45601": {
    "name": "blake2b-264",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45601
  },
  "45602": {
    "name": "blake2b-272",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45602
  },
  "45603": {
    "name": "blake2b-280",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45603
  },
  "45604": {
    "name": "blake2b-288",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45604
  },
  "45605": {
    "name": "blake2b-296",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45605
  },
  "45606": {
    "name": "blake2b-304",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45606
  },
  "45607": {
    "name": "blake2b-312",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45607
  },
  "45608": {
    "name": "blake2b-320",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45608
  },
  "45609": {
    "name": "blake2b-328",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45609
  },
  "45610": {
    "name": "blake2b-336",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45610
  },
  "45611": {
    "name": "blake2b-344",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45611
  },
  "45612": {
    "name": "blake2b-352",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45612
  },
  "45613": {
    "name": "blake2b-360",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45613
  },
  "45614": {
    "name": "blake2b-368",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45614
  },
  "45615": {
    "name": "blake2b-376",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45615
  },
  "45616": {
    "name": "blake2b-384",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45616
  },
  "45617": {
    "name": "blake2b-392",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45617
  },
  "45618": {
    "name": "blake2b-400",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45618
  },
  "45619": {
    "name": "blake2b-408",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45619
  },
  "45620": {
    "name": "blake2b-416",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45620
  },
  "45621": {
    "name": "blake2b-424",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45621
  },
  "45622": {
    "name": "blake2b-432",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45622
  },
  "45623": {
    "name": "blake2b-440",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45623
  },
  "45624": {
    "name": "blake2b-448",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45624
  },
  "45625": {
    "name": "blake2b-456",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45625
  },
  "45626": {
    "name": "blake2b-464",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45626
  },
  "45627": {
    "name": "blake2b-472",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45627
  },
  "45628": {
    "name": "blake2b-480",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45628
  },
  "45629": {
    "name": "blake2b-488",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45629
  },
  "45630": {
    "name": "blake2b-496",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45630
  },
  "45631": {
    "name": "blake2b-504",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45631
  },
  "45632": {
    "name": "blake2b-512",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45632
  },
  "45633": {
    "name": "blake2s-8",
    "tag": "multihash",
    "status": "draft",
    "description": "Blake2s consists of 32 output lengths that give different hashes",
    "code": 45633
  },
  "45634": {
    "name": "blake2s-16",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45634
  },
  "45635": {
    "name": "blake2s-24",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45635
  },
  "45636": {
    "name": "blake2s-32",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45636
  },
  "45637": {
    "name": "blake2s-40",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45637
  },
  "45638": {
    "name": "blake2s-48",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45638
  },
  "45639": {
    "name": "blake2s-56",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45639
  },
  "45640": {
    "name": "blake2s-64",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45640
  },
  "45641": {
    "name": "blake2s-72",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45641
  },
  "45642": {
    "name": "blake2s-80",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45642
  },
  "45643": {
    "name": "blake2s-88",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45643
  },
  "45644": {
    "name": "blake2s-96",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45644
  },
  "45645": {
    "name": "blake2s-104",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45645
  },
  "45646": {
    "name": "blake2s-112",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45646
  },
  "45647": {
    "name": "blake2s-120",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45647
  },
  "45648": {
    "name": "blake2s-128",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45648
  },
  "45649": {
    "name": "blake2s-136",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45649
  },
  "45650": {
    "name": "blake2s-144",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45650
  },
  "45651": {
    "name": "blake2s-152",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45651
  },
  "45652": {
    "name": "blake2s-160",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45652
  },
  "45653": {
    "name": "blake2s-168",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45653
  },
  "45654": {
    "name": "blake2s-176",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45654
  },
  "45655": {
    "name": "blake2s-184",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45655
  },
  "45656": {
    "name": "blake2s-192",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45656
  },
  "45657": {
    "name": "blake2s-200",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45657
  },
  "45658": {
    "name": "blake2s-208",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45658
  },
  "45659": {
    "name": "blake2s-216",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45659
  },
  "45660": {
    "name": "blake2s-224",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45660
  },
  "45661": {
    "name": "blake2s-232",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45661
  },
  "45662": {
    "name": "blake2s-240",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45662
  },
  "45663": {
    "name": "blake2s-248",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45663
  },
  "45664": {
    "name": "blake2s-256",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45664
  },
  "45825": {
    "name": "skein256-8",
    "tag": "multihash",
    "status": "draft",
    "description": "Skein256 consists of 32 output lengths that give different hashes",
    "code": 45825
  },
  "45826": {
    "name": "skein256-16",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45826
  },
  "45827": {
    "name": "skein256-24",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45827
  },
  "45828": {
    "name": "skein256-32",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45828
  },
  "45829": {
    "name": "skein256-40",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45829
  },
  "45830": {
    "name": "skein256-48",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45830
  },
  "45831": {
    "name": "skein256-56",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45831
  },
  "45832": {
    "name": "skein256-64",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45832
  },
  "45833": {
    "name": "skein256-72",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45833
  },
  "45834": {
    "name": "skein256-80",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45834
  },
  "45835": {
    "name": "skein256-88",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45835
  },
  "45836": {
    "name": "skein256-96",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45836
  },
  "45837": {
    "name": "skein256-104",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45837
  },
  "45838": {
    "name": "skein256-112",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45838
  },
  "45839": {
    "name": "skein256-120",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45839
  },
  "45840": {
    "name": "skein256-128",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45840
  },
  "45841": {
    "name": "skein256-136",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45841
  },
  "45842": {
    "name": "skein256-144",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45842
  },
  "45843": {
    "name": "skein256-152",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45843
  },
  "45844": {
    "name": "skein256-160",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45844
  },
  "45845": {
    "name": "skein256-168",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45845
  },
  "45846": {
    "name": "skein256-176",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45846
  },
  "45847": {
    "name": "skein256-184",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45847
  },
  "45848": {
    "name": "skein256-192",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45848
  },
  "45849": {
    "name": "skein256-200",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45849
  },
  "45850": {
    "name": "skein256-208",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45850
  },
  "45851": {
    "name": "skein256-216",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45851
  },
  "45852": {
    "name": "skein256-224",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45852
  },
  "45853": {
    "name": "skein256-232",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45853
  },
  "45854": {
    "name": "skein256-240",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45854
  },
  "45855": {
    "name": "skein256-248",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45855
  },
  "45856": {
    "name": "skein256-256",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45856
  },
  "45857": {
    "name": "skein512-8",
    "tag": "multihash",
    "status": "draft",
    "description": "Skein512 consists of 64 output lengths that give different hashes",
    "code": 45857
  },
  "45858": {
    "name": "skein512-16",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45858
  },
  "45859": {
    "name": "skein512-24",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45859
  },
  "45860": {
    "name": "skein512-32",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45860
  },
  "45861": {
    "name": "skein512-40",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45861
  },
  "45862": {
    "name": "skein512-48",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45862
  },
  "45863": {
    "name": "skein512-56",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45863
  },
  "45864": {
    "name": "skein512-64",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45864
  },
  "45865": {
    "name": "skein512-72",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45865
  },
  "45866": {
    "name": "skein512-80",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45866
  },
  "45867": {
    "name": "skein512-88",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45867
  },
  "45868": {
    "name": "skein512-96",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45868
  },
  "45869": {
    "name": "skein512-104",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45869
  },
  "45870": {
    "name": "skein512-112",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45870
  },
  "45871": {
    "name": "skein512-120",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45871
  },
  "45872": {
    "name": "skein512-128",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45872
  },
  "45873": {
    "name": "skein512-136",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45873
  },
  "45874": {
    "name": "skein512-144",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45874
  },
  "45875": {
    "name": "skein512-152",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45875
  },
  "45876": {
    "name": "skein512-160",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45876
  },
  "45877": {
    "name": "skein512-168",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45877
  },
  "45878": {
    "name": "skein512-176",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45878
  },
  "45879": {
    "name": "skein512-184",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45879
  },
  "45880": {
    "name": "skein512-192",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45880
  },
  "45881": {
    "name": "skein512-200",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45881
  },
  "45882": {
    "name": "skein512-208",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45882
  },
  "45883": {
    "name": "skein512-216",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45883
  },
  "45884": {
    "name": "skein512-224",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45884
  },
  "45885": {
    "name": "skein512-232",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45885
  },
  "45886": {
    "name": "skein512-240",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45886
  },
  "45887": {
    "name": "skein512-248",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45887
  },
  "45888": {
    "name": "skein512-256",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45888
  },
  "45889": {
    "name": "skein512-264",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45889
  },
  "45890": {
    "name": "skein512-272",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45890
  },
  "45891": {
    "name": "skein512-280",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45891
  },
  "45892": {
    "name": "skein512-288",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45892
  },
  "45893": {
    "name": "skein512-296",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45893
  },
  "45894": {
    "name": "skein512-304",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45894
  },
  "45895": {
    "name": "skein512-312",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45895
  },
  "45896": {
    "name": "skein512-320",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45896
  },
  "45897": {
    "name": "skein512-328",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45897
  },
  "45898": {
    "name": "skein512-336",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45898
  },
  "45899": {
    "name": "skein512-344",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45899
  },
  "45900": {
    "name": "skein512-352",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45900
  },
  "45901": {
    "name": "skein512-360",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45901
  },
  "45902": {
    "name": "skein512-368",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45902
  },
  "45903": {
    "name": "skein512-376",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45903
  },
  "45904": {
    "name": "skein512-384",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45904
  },
  "45905": {
    "name": "skein512-392",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45905
  },
  "45906": {
    "name": "skein512-400",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45906
  },
  "45907": {
    "name": "skein512-408",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45907
  },
  "45908": {
    "name": "skein512-416",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45908
  },
  "45909": {
    "name": "skein512-424",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45909
  },
  "45910": {
    "name": "skein512-432",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45910
  },
  "45911": {
    "name": "skein512-440",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45911
  },
  "45912": {
    "name": "skein512-448",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45912
  },
  "45913": {
    "name": "skein512-456",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45913
  },
  "45914": {
    "name": "skein512-464",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45914
  },
  "45915": {
    "name": "skein512-472",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45915
  },
  "45916": {
    "name": "skein512-480",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45916
  },
  "45917": {
    "name": "skein512-488",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45917
  },
  "45918": {
    "name": "skein512-496",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45918
  },
  "45919": {
    "name": "skein512-504",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45919
  },
  "45920": {
    "name": "skein512-512",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45920
  },
  "45921": {
    "name": "skein1024-8",
    "tag": "multihash",
    "status": "draft",
    "description": "Skein1024 consists of 128 output lengths that give different hashes",
    "code": 45921
  },
  "45922": {
    "name": "skein1024-16",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45922
  },
  "45923": {
    "name": "skein1024-24",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45923
  },
  "45924": {
    "name": "skein1024-32",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45924
  },
  "45925": {
    "name": "skein1024-40",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45925
  },
  "45926": {
    "name": "skein1024-48",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45926
  },
  "45927": {
    "name": "skein1024-56",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45927
  },
  "45928": {
    "name": "skein1024-64",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45928
  },
  "45929": {
    "name": "skein1024-72",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45929
  },
  "45930": {
    "name": "skein1024-80",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45930
  },
  "45931": {
    "name": "skein1024-88",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45931
  },
  "45932": {
    "name": "skein1024-96",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45932
  },
  "45933": {
    "name": "skein1024-104",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45933
  },
  "45934": {
    "name": "skein1024-112",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45934
  },
  "45935": {
    "name": "skein1024-120",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45935
  },
  "45936": {
    "name": "skein1024-128",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45936
  },
  "45937": {
    "name": "skein1024-136",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45937
  },
  "45938": {
    "name": "skein1024-144",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45938
  },
  "45939": {
    "name": "skein1024-152",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45939
  },
  "45940": {
    "name": "skein1024-160",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45940
  },
  "45941": {
    "name": "skein1024-168",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45941
  },
  "45942": {
    "name": "skein1024-176",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45942
  },
  "45943": {
    "name": "skein1024-184",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45943
  },
  "45944": {
    "name": "skein1024-192",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45944
  },
  "45945": {
    "name": "skein1024-200",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45945
  },
  "45946": {
    "name": "skein1024-208",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45946
  },
  "45947": {
    "name": "skein1024-216",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45947
  },
  "45948": {
    "name": "skein1024-224",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45948
  },
  "45949": {
    "name": "skein1024-232",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45949
  },
  "45950": {
    "name": "skein1024-240",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45950
  },
  "45951": {
    "name": "skein1024-248",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45951
  },
  "45952": {
    "name": "skein1024-256",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45952
  },
  "45953": {
    "name": "skein1024-264",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45953
  },
  "45954": {
    "name": "skein1024-272",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45954
  },
  "45955": {
    "name": "skein1024-280",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45955
  },
  "45956": {
    "name": "skein1024-288",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45956
  },
  "45957": {
    "name": "skein1024-296",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45957
  },
  "45958": {
    "name": "skein1024-304",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45958
  },
  "45959": {
    "name": "skein1024-312",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45959
  },
  "45960": {
    "name": "skein1024-320",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45960
  },
  "45961": {
    "name": "skein1024-328",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45961
  },
  "45962": {
    "name": "skein1024-336",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45962
  },
  "45963": {
    "name": "skein1024-344",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45963
  },
  "45964": {
    "name": "skein1024-352",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45964
  },
  "45965": {
    "name": "skein1024-360",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45965
  },
  "45966": {
    "name": "skein1024-368",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45966
  },
  "45967": {
    "name": "skein1024-376",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45967
  },
  "45968": {
    "name": "skein1024-384",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45968
  },
  "45969": {
    "name": "skein1024-392",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45969
  },
  "45970": {
    "name": "skein1024-400",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45970
  },
  "45971": {
    "name": "skein1024-408",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45971
  },
  "45972": {
    "name": "skein1024-416",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45972
  },
  "45973": {
    "name": "skein1024-424",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45973
  },
  "45974": {
    "name": "skein1024-432",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45974
  },
  "45975": {
    "name": "skein1024-440",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45975
  },
  "45976": {
    "name": "skein1024-448",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45976
  },
  "45977": {
    "name": "skein1024-456",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45977
  },
  "45978": {
    "name": "skein1024-464",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45978
  },
  "45979": {
    "name": "skein1024-472",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45979
  },
  "45980": {
    "name": "skein1024-480",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45980
  },
  "45981": {
    "name": "skein1024-488",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45981
  },
  "45982": {
    "name": "skein1024-496",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45982
  },
  "45983": {
    "name": "skein1024-504",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45983
  },
  "45984": {
    "name": "skein1024-512",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45984
  },
  "45985": {
    "name": "skein1024-520",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45985
  },
  "45986": {
    "name": "skein1024-528",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45986
  },
  "45987": {
    "name": "skein1024-536",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45987
  },
  "45988": {
    "name": "skein1024-544",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45988
  },
  "45989": {
    "name": "skein1024-552",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45989
  },
  "45990": {
    "name": "skein1024-560",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45990
  },
  "45991": {
    "name": "skein1024-568",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45991
  },
  "45992": {
    "name": "skein1024-576",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45992
  },
  "45993": {
    "name": "skein1024-584",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45993
  },
  "45994": {
    "name": "skein1024-592",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45994
  },
  "45995": {
    "name": "skein1024-600",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45995
  },
  "45996": {
    "name": "skein1024-608",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45996
  },
  "45997": {
    "name": "skein1024-616",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45997
  },
  "45998": {
    "name": "skein1024-624",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45998
  },
  "45999": {
    "name": "skein1024-632",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 45999
  },
  "46000": {
    "name": "skein1024-640",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46000
  },
  "46001": {
    "name": "skein1024-648",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46001
  },
  "46002": {
    "name": "skein1024-656",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46002
  },
  "46003": {
    "name": "skein1024-664",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46003
  },
  "46004": {
    "name": "skein1024-672",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46004
  },
  "46005": {
    "name": "skein1024-680",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46005
  },
  "46006": {
    "name": "skein1024-688",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46006
  },
  "46007": {
    "name": "skein1024-696",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46007
  },
  "46008": {
    "name": "skein1024-704",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46008
  },
  "46009": {
    "name": "skein1024-712",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46009
  },
  "46010": {
    "name": "skein1024-720",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46010
  },
  "46011": {
    "name": "skein1024-728",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46011
  },
  "46012": {
    "name": "skein1024-736",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46012
  },
  "46013": {
    "name": "skein1024-744",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46013
  },
  "46014": {
    "name": "skein1024-752",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46014
  },
  "46015": {
    "name": "skein1024-760",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46015
  },
  "46016": {
    "name": "skein1024-768",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46016
  },
  "46017": {
    "name": "skein1024-776",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46017
  },
  "46018": {
    "name": "skein1024-784",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46018
  },
  "46019": {
    "name": "skein1024-792",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46019
  },
  "46020": {
    "name": "skein1024-800",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46020
  },
  "46021": {
    "name": "skein1024-808",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46021
  },
  "46022": {
    "name": "skein1024-816",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46022
  },
  "46023": {
    "name": "skein1024-824",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46023
  },
  "46024": {
    "name": "skein1024-832",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46024
  },
  "46025": {
    "name": "skein1024-840",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46025
  },
  "46026": {
    "name": "skein1024-848",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46026
  },
  "46027": {
    "name": "skein1024-856",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46027
  },
  "46028": {
    "name": "skein1024-864",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46028
  },
  "46029": {
    "name": "skein1024-872",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46029
  },
  "46030": {
    "name": "skein1024-880",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46030
  },
  "46031": {
    "name": "skein1024-888",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46031
  },
  "46032": {
    "name": "skein1024-896",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46032
  },
  "46033": {
    "name": "skein1024-904",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46033
  },
  "46034": {
    "name": "skein1024-912",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46034
  },
  "46035": {
    "name": "skein1024-920",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46035
  },
  "46036": {
    "name": "skein1024-928",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46036
  },
  "46037": {
    "name": "skein1024-936",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46037
  },
  "46038": {
    "name": "skein1024-944",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46038
  },
  "46039": {
    "name": "skein1024-952",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46039
  },
  "46040": {
    "name": "skein1024-960",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46040
  },
  "46041": {
    "name": "skein1024-968",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46041
  },
  "46042": {
    "name": "skein1024-976",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46042
  },
  "46043": {
    "name": "skein1024-984",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46043
  },
  "46044": {
    "name": "skein1024-992",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46044
  },
  "46045": {
    "name": "skein1024-1000",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46045
  },
  "46046": {
    "name": "skein1024-1008",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46046
  },
  "46047": {
    "name": "skein1024-1016",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46047
  },
  "46048": {
    "name": "skein1024-1024",
    "tag": "multihash",
    "status": "draft",
    "description": "",
    "code": 46048
  },
  "46049": {
    "name": "xxh-32",
    "tag": "hash",
    "status": "draft",
    "description": "Extremely fast non-cryptographic hash algorithm",
    "code": 46049
  },
  "46050": {
    "name": "xxh-64",
    "tag": "hash",
    "status": "draft",
    "description": "Extremely fast non-cryptographic hash algorithm",
    "code": 46050
  },
  "46051": {
    "name": "xxh3-64",
    "tag": "hash",
    "status": "draft",
    "description": "Extremely fast non-cryptographic hash algorithm",
    "code": 46051
  },
  "46052": {
    "name": "xxh3-128",
    "tag": "hash",
    "status": "draft",
    "description": "Extremely fast non-cryptographic hash algorithm",
    "code": 46052
  },
  "46081": {
    "name": "poseidon-bls12_381-a2-fc1",
    "tag": "multihash",
    "status": "permanent",
    "description": "Poseidon using BLS12-381 and arity of 2 with Filecoin parameters",
    "code": 46081
  },
  "46082": {
    "name": "poseidon-bls12_381-a2-fc1-sc",
    "tag": "multihash",
    "status": "draft",
    "description": "Poseidon using BLS12-381 and arity of 2 with Filecoin parameters - high-security variant",
    "code": 46082
  },
  "46083": {
    "name": "urdca-2015-canon",
    "tag": "ipld",
    "status": "draft",
    "description": "The result of canonicalizing an input according to URDCA-2015 and then expressing its hash value as a multihash value.",
    "code": 46083
  },
  "46337": {
    "name": "ssz",
    "tag": "serialization",
    "status": "draft",
    "description": "SimpleSerialize (SSZ) serialization",
    "code": 46337
  },
  "46338": {
    "name": "ssz-sha2-256-bmt",
    "tag": "multihash",
    "status": "draft",
    "description": "SSZ Merkle tree root using SHA2-256 as the hashing function and SSZ serialization for the block binary",
    "code": 46338
  },
  "46593": {
    "name": "json-jcs",
    "tag": "ipld",
    "status": "draft",
    "description": "The result of canonicalizing an input according to JCS - JSON Canonicalisation Scheme (RFC 8785)",
    "code": 46593
  },
  "52225": {
    "name": "iscc",
    "tag": "softhash",
    "status": "draft",
    "description": "ISCC (International Standard Content Code) - similarity preserving hash",
    "code": 52225
  },
  "52753": {
    "name": "zeroxcert-imprint-256",
    "tag": "zeroxcert",
    "status": "draft",
    "description": "0xcert Asset Imprint (root hash)",
    "code": 52753
  },
  "53248": {
    "name": "nonstandard-sig",
    "tag": "varsig",
    "status": "deprecated",
    "description": "Namespace for all not yet standard signature algorithms",
    "code": 53248
  },
  "53479": {
    "name": "es256k",
    "tag": "varsig",
    "status": "draft",
    "description": "ES256K Siganture Algorithm (secp256k1)",
    "code": 53479
  },
  "53482": {
    "name": "bls-12381-g1-sig",
    "tag": "varsig",
    "status": "draft",
    "description": "G1 signature for BLS-12381-G2",
    "code": 53482
  },
  "53483": {
    "name": "bls-12381-g2-sig",
    "tag": "varsig",
    "status": "draft",
    "description": "G2 signature for BLS-12381-G1",
    "code": 53483
  },
  "53485": {
    "name": "eddsa",
    "tag": "varsig",
    "status": "draft",
    "description": "Edwards-Curve Digital Signature Algorithm",
    "code": 53485
  },
  "53649": {
    "name": "eip-191",
    "tag": "varsig",
    "status": "draft",
    "description": "EIP-191 Ethereum Signed Data Standard",
    "code": 53649
  },
  "60241": {
    "name": "jwk_jcs-pub",
    "tag": "key",
    "status": "draft",
    "description": "JSON object containing only the required members of a JWK (RFC 7518 and RFC 7517) representing the public key. Serialisation based on JCS (RFC 8785)",
    "code": 60241
  },
  "61697": {
    "name": "fil-commitment-unsealed",
    "tag": "filecoin",
    "status": "permanent",
    "description": "Filecoin piece or sector data commitment merkle node/root (CommP & CommD)",
    "code": 61697
  },
  "61698": {
    "name": "fil-commitment-sealed",
    "tag": "filecoin",
    "status": "permanent",
    "description": "Filecoin sector data commitment merkle node/root - sealed and replicated (CommR)",
    "code": 61698
  },
  "7367777": {
    "name": "plaintextv2",
    "tag": "multiaddr",
    "status": "draft",
    "description": "",
    "code": 7367777
  },
  "8417572": {
    "name": "holochain-adr-v0",
    "tag": "holochain",
    "status": "draft",
    "description": "Holochain v0 address    + 8 R-S (63 x Base-32)",
    "code": 8417572
  },
  "8483108": {
    "name": "holochain-adr-v1",
    "tag": "holochain",
    "status": "draft",
    "description": "Holochain v1 address    + 8 R-S (63 x Base-32)",
    "code": 8483108
  },
  "9728292": {
    "name": "holochain-key-v0",
    "tag": "holochain",
    "status": "draft",
    "description": "Holochain v0 public key + 8 R-S (63 x Base-32)",
    "code": 9728292
  },
  "9793828": {
    "name": "holochain-key-v1",
    "tag": "holochain",
    "status": "draft",
    "description": "Holochain v1 public key + 8 R-S (63 x Base-32)",
    "code": 9793828
  },
  "10645796": {
    "name": "holochain-sig-v0",
    "tag": "holochain",
    "status": "draft",
    "description": "Holochain v0 signature  + 8 R-S (63 x Base-32)",
    "code": 10645796
  },
  "10711332": {
    "name": "holochain-sig-v1",
    "tag": "holochain",
    "status": "draft",
    "description": "Holochain v1 signature  + 8 R-S (63 x Base-32)",
    "code": 10711332
  },
  "11639056": {
    "name": "skynet-ns",
    "tag": "namespace",
    "status": "draft",
    "description": "Skynet Namespace",
    "code": 11639056
  },
  "11704592": {
    "name": "arweave-ns",
    "tag": "namespace",
    "status": "draft",
    "description": "Arweave Namespace",
    "code": 11704592
  },
  "11770128": {
    "name": "subspace-ns",
    "tag": "namespace",
    "status": "draft",
    "description": "Subspace Network Namespace",
    "code": 11770128
  },
  "11835664": {
    "name": "kumandra-ns",
    "tag": "namespace",
    "status": "draft",
    "description": "Kumandra Network Namespace",
    "code": 11835664
  },
  "13636096": {
    "name": "es256",
    "tag": "varsig",
    "status": "draft",
    "description": "ES256 Signature Algorithm",
    "code": 13636096
  },
  "13636097": {
    "name": "es284",
    "tag": "varsig",
    "status": "draft",
    "description": "ES384 Signature Algorithm",
    "code": 13636097
  },
  "13636098": {
    "name": "es512",
    "tag": "varsig",
    "status": "draft",
    "description": "ES512 Signature Algorithm",
    "code": 13636098
  },
  "13636101": {
    "name": "rs256",
    "tag": "varsig",
    "status": "draft",
    "description": "RS256 Signature Algorithm",
    "code": 13636101
  },
  "13639680": {
    "name": "scion",
    "tag": "multiaddr",
    "status": "draft",
    "description": "SCION Internet architecture",
    "code": 13639680
  }
}